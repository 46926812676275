import { useState,useEffect } from "react"

const Timer = () => {
//  const [days,setDays] = useState(0);
 const [hours,setHours] = useState(0);
 const [minutes,setMinutes] = useState(0);
 const [seconds,setSeconds] = useState(0);

 const getTime = () => {
    const time = new Date();
    
    // setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    // setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setHours(Math.floor(time.getHours()));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
    const date = new Date();
    const hour = date.getHours();
    console.log(hour)
 };

 useEffect(() => {
    const interval = setInterval(() => getTime(Date.now()),1000);

    return () => clearInterval(interval);
 },[]);

 return(
    <div className="timer d-flex" role="timer">
        <p id="hours">{hours < 10 ? "0" + hours : hours}:</p>
        <p id="minutes">{minutes < 10 ? "0" + minutes : minutes}:</p>
        <p id="day">{seconds < 10 ? "0" + seconds : seconds}</p>
    </div>
 );
};

export default Timer;